import { type ActionData, type ActionError } from '../../types/types';

export type SubmitForm = (
  actionId: string,
  formId: string,
  values: Record<string, any>,
) => void;

export type ActionResult = ActionData | ActionError;

export type ActionResultsById = Record<string, ActionResult | undefined>;

export interface ControllerProps {
  formId: string;
  actionResultsById: ActionResultsById;
  submitForm: SubmitForm;
}

export function isActionError(
  actionResult: ActionResult,
): actionResult is ActionError {
  return (actionResult as ActionError).error !== undefined;
}
