import { type FormError, type FormValues } from '@wix/form-viewer/widget';
import { uniqueId } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  type ActionResultsById,
  isActionError,
  type SubmitForm,
} from '../../types';
import { SubmitButton } from './SubmitButton';

export const useSubmitButton = (
  formId: string,
  formValues: FormValues,
  submitForm: SubmitForm,
  actionResultsById: ActionResultsById,
) => {
  const actionId = useRef<string>();
  const [responseErrors, setResponseErrors] = useState<FormError[]>();
  const [responseValues, setResponseValues] = useState<FormValues>();
  const [submitState, setSubmitState] = useState<
    'initial' | 'in-progress' | 'success' | 'error'
  >('initial');

  const handleSubmitClick = useCallback(async () => {
    setSubmitState('in-progress');
    actionId.current = uniqueId();
    submitForm(actionId.current, formId, formValues);
  }, [submitForm, formId, formValues]);

  const submitResult = actionId.current && actionResultsById[actionId.current];
  useEffect(() => {
    if (!submitResult) {
      return;
    }
    actionId.current = undefined;
    if (isActionError(submitResult)) {
      const violations =
        submitResult.error?.details?.validationError?.fieldViolations || [];
      const validationErrors = violations.find(
        (violation: any) => violation.ruleName === 'SUBMISSION_VALIDATION',
      )?.data?.errors;
      if (validationErrors) {
        setResponseErrors(validationErrors);
      }
      setSubmitState('error');
      return;
    }
    setResponseValues(submitResult.data.submissions);
    setSubmitState('success');
  }, [submitResult]);

  const submitButton = (
    <SubmitButton status={submitState} onClick={handleSubmitClick} />
  );

  return { submitState, responseErrors, responseValues, submitButton };
};
