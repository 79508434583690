import classNames from 'classnames';
import React, { type ReactNode } from 'react';
import { Check } from '@wix/wix-ui-icons-common';
import styles from './Section.scss';

export interface SectionProps {
  dataHook?: string;
  position: number;
  title: string;
  state: 'active' | 'completed' | 'disabled';
  action?: React.ReactNode;
  children: ReactNode;
}

export const Section: React.FC<SectionProps> = ({
  dataHook,
  position,
  title,
  action,
  state,
  children,
}) => {
  return (
    <div
      className={classNames(styles[state], { [styles.narrowSection]: false })}
      data-hook={dataHook}
    >
      <header className={styles.header}>
        <h2>
          <span>{state === 'completed' ? <Check /> : position}</span> {title}
        </h2>
        {action}
      </header>
      {state !== 'disabled' && children ? (
        <div className={styles.content}>{children}</div>
      ) : null}
    </div>
  );
};
