import {
  type FormError,
  type FormValues,
  FormViewer,
  type FormViewerHandle,
} from '@wix/form-viewer/widget';
import {
  type IStyle,
  useBi,
  useErrorMonitor,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getStyle } from '@wix/form-fields';
import { Button } from 'wix-ui-tpa/cssVars';
import { FormPreview } from '../../../shared/FormPreview';
import { type ControllerProps } from '../../types';
import { FormStatus } from '../FormStatus';
import { Action, Section } from '../Section';
import { StaticTheme } from '../StaticTheme';
import { useSubmitButton } from '../SubmitButton';
import { classes } from './Form.st.css';

type FormProps = ControllerProps;

export const Form = ({ formId, submitForm, actionResultsById }: FormProps) => {
  const bi = useBi();
  const [sectionState, setSectionState] = useState<
    'active' | 'completed' | 'disabled'
  >('active');
  const errorMonitor = useErrorMonitor();
  const { i18n } = useTranslation();

  const formViewer = useRef<FormViewerHandle>(null);
  const [isFormValidating, setIsFormValidating] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [formErrors, setFormErrors] = useState<FormError[]>([]);

  const { submitButton, submitState, responseErrors, responseValues } =
    useSubmitButton(formId, formValues, submitForm, actionResultsById);

  const handleFormChange = useCallback((values: FormValues) => {
    setFormValues(values);
  }, []);

  const handleFormValidate = useCallback((errors: FormError[]) => {
    setFormErrors(errors);
  }, []);

  const handleFormError = useCallback(
    (error: Error) => {
      errorMonitor.captureException(error);
    },
    [errorMonitor],
  );

  const handleContinueClick = useCallback(async () => {
    if (!formViewer.current) {
      return;
    }

    setIsFormValidating(true);
    const isFormValid = await formViewer.current.validate();
    setIsFormValidating(false);

    if (!isFormValid) {
      return;
    }

    setSectionState('completed');
  }, []);

  useEffect(() => {
    if (responseErrors) {
      setSectionState('active');
      setFormErrors(responseErrors);
    }
  }, [responseErrors]);

  useEffect(() => {
    if (responseValues) {
      setFormValues(responseValues);
    }
  }, [responseValues]);

  const canEdit =
    submitState === 'in-progress' || submitState === 'success' ? false : true;

  return (
    <>
      <Section
        position={1}
        title="Additional Information"
        state={sectionState}
        action={
          canEdit && sectionState === 'completed' ? (
            <Action onClick={() => setSectionState('active')}>Edit</Action>
          ) : undefined
        }
      >
        {sectionState === 'active' ? (
          <>
            <StaticTheme name="additional-info" style={getStyle() as IStyle}>
              <FormViewer
                ref={formViewer}
                bi={bi}
                i18n={i18n}
                formId={formId}
                values={formValues}
                errors={formErrors}
                onChange={handleFormChange}
                onValidate={handleFormValidate}
                onError={handleFormError}
              />
            </StaticTheme>
            <div className={classes.actionContainer}>
              <Button
                className={classes.button}
                onClick={handleContinueClick}
                disabled={isFormValidating}
              >
                Continue
              </Button>
            </div>
          </>
        ) : (
          <FormPreview formId={formId} formValues={formValues} />
        )}
      </Section>
      {sectionState === 'completed' ? submitButton : null}
      <FormStatus status={submitState} />
    </>
  );
};
