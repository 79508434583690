import React, { type ReactNode, useMemo } from 'react';
// @ts-expect-error
import { getProcessedCss } from 'tpa-style-webpack-plugin/runtime';
import { type IStyle, useEnvironment } from '@wix/yoshi-flow-editor';

interface StaticThemeProps {
  name: string;
  style: IStyle;
  children: ReactNode;
}

export const StaticTheme: React.FC<StaticThemeProps> = ({
  name,
  style,
  children,
}) => {
  const { isRTL, isMobile, isEditorX, dimensions } = useEnvironment();
  const prefixSelector = name ? `.${name}` : '';
  const css = useMemo(
    () =>
      getProcessedCss(style, {
        prefixSelector,
        isRTL: !!isRTL,
        isMobile: !!isMobile,
        isEditorX: !!isEditorX,
        strictMode: true,
        dimensions,
      }),
    [style, prefixSelector, isRTL, isMobile, isEditorX, dimensions],
  );

  return (
    <div className={name}>
      <style>{css}</style>
      {children}
    </div>
  );
};
