import React, { type ReactNode } from 'react';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa/cssVars';
import { classes } from './Actions.st.css';

interface ActionProps {
  dataHook?: string;
  onClick: () => void;
  children: ReactNode;
}

export const Action: React.FC<ActionProps> = ({
  dataHook,
  onClick,
  children,
}) => {
  return (
    <TextButton
      data-hook={dataHook}
      className={classes.root}
      priority={TextButtonPriority.link}
      onClick={onClick}
    >
      {children}
    </TextButton>
  );
};
