import React from 'react';
import { Button } from 'wix-ui-tpa/cssVars';
import { classes } from './SubmitButton.st.css';

interface SubmitButtonProps {
  status: 'initial' | 'in-progress' | 'success' | 'error';
  onClick: () => void;
}

export const SubmitButton = ({ status, onClick }: SubmitButtonProps) => {
  return (
    <div className={classes.container}>
      <Button
        className={classes.button}
        onClick={onClick}
        disabled={status === 'in-progress' || status === 'success'}
      >
        Submit
      </Button>
    </div>
  );
};
